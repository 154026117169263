import app from '../../app';
import selectTags from '../../modals/selectTags';
import { getTagPath } from '../../helpers';

app.controller('editBannedEntryCtrl', [
  '$uibModalInstance',
  '$scope',
  'BannedList',
  'entry',
  'known',
  'companySettings',
  'SpinnerService',
  '$q',
  'MessagesService',
  'ModalService',
  'TagService',
  function (
    $uibModalInstance,
    $scope,
    BannedList,
    entry,
    known,
    companySettings,
    SpinnerService,
    $q,
    MessagesService,
    ModalService,
    TagService
  ) {
    const vm = this,
      isEdit = !!entry.id,
      normalize = function (value) {
        return value.toLowerCase().replace(/["]+/g, '').replace(/\s+/g, ' ').trim();
      },
      addRecord = function (data) {
        return BannedList.create(data).$promise;
      },
      updateRecord = SpinnerService.wrap(function (data) {
        return BannedList.update({ where: { id: data.id } }, data).$promise.then(function (resp) {
          return resp.count === 1 ? data : $q.reject(`Haven't managed to update anything`);
        });
      }),
      apply = function (data) {
        return (isEdit ? updateRecord : addRecord)(data)
          .then(function (respData) {
            MessagesService.success(isEdit ? 'COMMON.MESSAGES.UPDATED' : 'COMMON.MESSAGES.ADDED');
            $uibModalInstance.close(respData);
          })
          .catch(function (err) {
            MessagesService.error(err);
          });
      };

    vm.title = isEdit
      ? 'SETTINGS.BANNED.EDIT_BANNED_CHEMICAL'
      : 'SETTINGS.BANNED.ADD_BANNED_CHEMICAL';
    vm.action = isEdit ? 'COMMON.ACTIONS.SAVE' : 'COMMON.ACTIONS.ADD';
    vm.availableLanguages = companySettings.disabledMultilanguage
      ? [$scope.languages[0]]
      : $scope.languages;
    vm.language = $scope.languages[0];
    vm.entry = {
      id: entry.id,
      companyId: entry.companyId,
      name: entry.name || '',
      type: entry.type,
      tags: entry.tags
    };
    $scope.languages.forEach(lang => {
      vm.entry[`comment_${lang}`] = entry[[`comment_${lang}`]] || '';
    });
    vm.entry[`comment_en`] = entry.comment || '';

    (vm.origName = entry.name), (vm.known = known);

    vm.dismiss = $uibModalInstance.dismiss;
    vm.apply = function () {
      vm.entry.comment = vm.entry.comment_en;
      delete vm.entry.comment_en;
      vm.entry.title = normalize(vm.entry.name);
      return apply(vm.entry);
    };

    vm.companyTags = [];
    vm.selectedTags = '';
    const setSelectedTags = function () {
      vm.selectedTags =
        vm.entry.tags && vm.entry.tags.length
          ? vm.entry.tags.map(tagId => getTagPath(vm.companyTags, tagId).join(' / ')).join('; ')
          : '';
    };
    vm.isLoading = true;
    TagService.getCompanyTags(vm.entry.companyId, { force: true }).then((tags = []) => {
      vm.companyTags = tags;
      setSelectedTags();
      vm.isLoading = false;
    });

    vm.selectTags = function () {
      return ModalService.open(
        selectTags(vm.entry.companyId, {
          showAndOrDropdown: false,
          checkedTags:
            vm.entry.tags && vm.entry.tags.length
              ? vm.entry.tags.map(id => ({ id, checked: true }))
              : [],
          allowSearchByParentTags: false,
          message: 'TAGS.FILTER_BY_TAGS',
          saveButtonText: 'COMMON.ACTIONS.SELECT'
        })
      ).then(result => {
        vm.entry.tags = (result.tags || []).map(tag => tag.id);
        setSelectedTags();
      });
    };
  }
]);
