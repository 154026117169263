import app from '../../app';

app.directive('bannedMessage', [
  '$rootScope',
  '$translate',
  '$q',
  function ($rootScope, $translate, $q) {
    return {
      restrict: 'E',
      scope: {
        banned: '=',
        filters: '='
      },
      templateUrl: require('./bannedMessage.html'),
      link: function (scope) {
        const composeTemplate = function (template, value) {
            return template.replace('{chemical}', '"' + value + '"');
          },
          trKey = 'COMMON.MESSAGES.BANNED',
          composeComment = () => {
            scope.comment =
              $rootScope.siteLanguage === 'en'
                ? scope.banned.comment
                : scope.banned[`comment_${$rootScope.siteLanguage}`] || scope.banned.comment;

            //get not empty comment
            if (!scope.comment) {
              $rootScope.languages.some(lang => {
                scope.comment =
                  lang === 'en' ? scope.banned.comment : scope.banned[`comment_${lang}`];

                return scope.comment;
              });
            }
          },
          composeGeneric = function (value, company) {
            return $translate([trKey], {
              name: value,
              company: company
            }).then(function (tr) {
              return tr[trKey];
            });
          },
          composeGenericWithTags = function (value, tags, company) {
            const translationKey = 'COMMON.MESSAGES.BANNED_WITH_TAGS';
            return $translate([translationKey], {
              name: value,
              tags: tags,
              company: company
            }).then(function (tr) {
              return tr[translationKey];
            });
          },
          company = ($rootScope.currentUser || {}).company || '';

        composeComment();

        let template =
          ($rootScope.companySettings || {})[`bannedTemplateMessage_${$rootScope.siteLanguage}`] ||
          ($rootScope.companySettings || {}).bannedTemplateMessage;

        scope.composeMsg = function () {
          return template
            ? $q.resolve(composeTemplate(template, scope.banned.name || scope.banned.title))
            : composeGeneric(scope.banned.name || scope.banned.title, company);
        };

        scope.composeMsgWithTags = function () {
          const tagFilters = scope.filters.filter(
            filter => filter.field === 'tag' && scope.banned.tags.includes(filter.id)
          );
          if (!tagFilters.length) {
            return $q.resolve('');
          }
          const bannedTags = tagFilters
            .map(filter =>
              (
                filter[`fullPathTitle_${$rootScope.siteLanguage}`] ||
                filter[`title_${$rootScope.siteLanguage}`] ||
                filter.title
              ).replace(/>/g, '/')
            )
            .join('; ');
          return composeGenericWithTags(
            scope.banned.name || scope.banned.title,
            bannedTags,
            company
          );
        };

        scope.$watch('banned', function (banned) {
          scope.banned = banned;
          if (scope.banned.tags && scope.banned.tags.length) {
            scope.composeMsgWithTags().then(function (msg) {
              scope.msg = msg;
            });
          } else {
            scope.composeMsg().then(function (msg) {
              scope.msg = msg;
            });
          }
        });

        $rootScope.$watch('siteLanguage', function (newValue, oldValue) {
          if (newValue === oldValue) return;
          if (scope.banned.tags && scope.banned.tags.length) {
            scope.composeMsgWithTags().then(function (msg) {
              scope.msg = msg;
            });
          } else {
            template =
              ($rootScope.companySettings || {})[
                `bannedTemplateMessage_${$rootScope.siteLanguage}`
              ] || ($rootScope.companySettings || {}).bannedTemplateMessage;
            scope.composeMsg().then(function (msg) {
              scope.msg = msg;
            });
          }
          composeComment();
        });
      }
    };
  }
]);
